<template>
  <div>
    <!-- 头部 -->
    <Header></Header>
    <div class="BoxHeight2 bbox pb30">
      <div class="list"
           v-if="messageList.length > 0">
        <van-swipe-cell v-for="(message, index) in messageList"
                        :key="message.id">
          <div class="item bbox plr13">
            <div class="flex justify-between align-center">
              <div class="text333 f16">
                <span class="positionr">
                  <span class="textred tip positiona"
                        v-if="message.isRead != '1'">●</span>
                  {{ message.title }}
                </span>
                <span class="tabBtn"
                      @click="handleRead(message, index)"
                      v-if="message.isRead != '1'">{{$t('my.sign')}}</span>
              </div>
              <div class="text999 f12">{{ message.createDt | dataFormat }}</div>
            </div>
            <div class="text999 f13 mt5">
              {{ message.content }}
            </div>
          </div>
          <template #right>
            <van-button square
                        text="删除"
                        type="danger"
                        class="delete-button hp100"
                        @click="del(message)" />
          </template>
        </van-swipe-cell>
      </div>
      <!-- 这里的加载中的插件需要去入口文件引入vant组件 -->
      <van-loading type="spinner"
                   color="white"
                   size="40px"
                   style="position: fixed; top: 0; left: 0"
                   v-if="showLoading" />
      <div v-if="messageList.length == 0"
           class="kong positiona">
        <img src="../../assets/images/kong.png"
             alt=""
             width="150" />
        <div class="f15 textc mt15 text999">{{$t('my.noMessage')}}~</div>
      </div>
    </div>
    <!-- 页脚 -->
    <Footer class="positionf footer"></Footer>
  </div>
</template>

<script>
import Header from "../../components/commonHead.vue";
import Footer from "../../components/footer.vue";
import { Dialog, Toast } from "vant";
export default {
  components: { Header, Footer },
  data () {
    return {
      readFlag: [false, false, false],
      list: [1],
      messageList: [],
      currPage: 1,
      totalPage: 1,
      pageSize: 30,
      showLoading: false,
    };
  },

  methods: {
    onScroll () {
      //console.log("滚动了")
      // 可滚动容器的高度
      var innerHeight = document.querySelector(".BoxHeight2").clientHeight;
      // 屏幕尺寸高度
      var outerHeight = document.documentElement.clientHeight - 70;
      // 可滚动容器超出当前窗口显示范围的高度
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现innerHeight <= (outerHeight + scrollTop)的情况，严格来讲，是接近底部。
      console.log(
        "可滚动容器的高度:" + innerHeight,
        "屏幕尺寸高度:" + outerHeight,
        "可滚动容器超出当前窗口显示范围的高度:" + scrollTop
      );
      if (outerHeight + scrollTop >= innerHeight) {
        console.log("触底了");
        // 加载更多操作
        this.currPage++;
        this.loadMessageList();
      }
    },
    loadMessageList () {
      this.showLoading = true;
      var sendData = {};
      sendData.memberCode = localStorage.getItem("memberCode");
      sendData.currPage = this.currPage;
      sendData.pageSize = this.pageSize;
      var that = this;
      this.$http
        .post("memberMsg/queryMsgPageList", sendData)
        .then(function (res) {
          if (200 == res.data.code) {
            // that.messageList = res.data.data.data;
            for (var i = 0; i < res.data.data.data.length; i++) {
              that.messageList.push(res.data.data.data[i]);
            }
            that.currPage = res.data.data.currPage;
            that.totalPage = res.data.data.pageCount;
            that.showLoading = false;
          }
        });
    },
    // 删除
    del (message) {
      Dialog.confirm({
        title: "温馨提示",
        message: "确定删除吗？",
      })
        .then(() => {
          // on confirm
          var sendData = {};
          sendData.id = message.id;
          var that = this;
          this.$http.post("memberMsg/deleteMsg", sendData).then(function (res) {
            if (200 == res.data.code) {
              var index = that.messageList.indexOf(message);
              that.messageList.splice(index, 1);
              Toast("删除成功");
            }
          });
        })
        .catch(() => {
          // on cancel
          Toast("取消删除");
        });
    },
    handleRead (message, index) {
      var sendData = {};
      sendData.id = message.id;
      var that = this;
      this.$http
        .post("memberMsg/updateMsgIsRead", sendData)
        .then(function (res) {
          if (200 == res.data.code) {
            var index = that.messageList.indexOf(message);
            that.messageList[index].isRead = "1";
          }
        });
    },
  },
  created () {
    this.loadMessageList();
    // 监听滚动事件
    window.addEventListener("scroll", this.onScroll);
  },
  mounted () {
    // 监听滚动事件
    window.addEventListener("scroll", this.onScroll);
  },
};
</script>

<style scoped>
.item {
  border-bottom: 1px solid #eee;
  padding: 10px;
}
.van-swipe-cell:last-of-type .item {
  border-bottom: none;
}
.del {
  width: 58px;
}
.tip {
  top: -7px;
  right: -7px;
}
.tabBtn {
  display: inline-block;
  border: 1px solid #b3d8ff;
  background-color: #e9f4ff;
  border-radius: 5px;
  padding: 0 8px;
  font-size: 12px;
  color: #409eff;
  margin-left: 25px;
}
.footer {
  bottom: 0;
  left: 0;
  width: 100vw;
}
</style>